// dependencies.
import React, { useEffect, useRef, useState } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import Slider from 'react-slick'
import classNames from 'classnames'
// utils.
import useDimensions from 'js/utils/hooks/useDimensions'

// Styles & Images
import 'src/components/carousel/FadeCarousel/scss/styles.scss'

// Helpers:
const WRAPPER_MAX_WIDTH = 1080

// Partials:
const ArrowIcon = ({ direction, disabled, onClick }) => (
  <span
    className={classNames(`x__controls__arrow x__controls__arrow--${direction}`, {
      'x__controls__arrow--disabled': disabled,
    })}
    tabIndex={0}
    onClick={onClick}
  >
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.9525 19L13.543 17.6L18.1843 12.9899H2V11.0101H18.1843L13.543 6.4L14.9525 5L22 12L14.9525 19Z" />
    </svg>
  </span>
)

const Caption = ({ isMobile, items, activeIndex, onClick, onTouchStart, onTouchEnd }) => {
  const activeItem = items[activeIndex]

  return (
    <div className="x__caption" onTouchStart={onTouchStart} onTouchEnd={onTouchEnd}>
      <span className="x__caption__index">{`${activeIndex + 1}/${items.length}`}</span>
      {activeItem.title && (
        <span
          className="x__caption__heading"
          dangerouslySetInnerHTML={{ __html: activeItem.title }}
        />
      )}
      {activeItem.copy && <span className="x__caption__description">{activeItem.copy}</span>}

      {isMobile && (
        <div className="x__indicators">
          {items.map((item, i) => (
            <i
              key={i}
              className={classNames('x__indicators__dot', {
                'x__indicators__dot--active': activeIndex === i,
              })}
              tabIndex={0}
              onClick={() => onClick(i)}
            />
          ))}
        </div>
      )}
    </div>
  )
}

// Main component:
const FadeCarousel = ({ items = [], visibleItemsCount = 3, chromes = null }) => {
  const carouselRef = useRef(null)
  const timeoutRef = useRef(null)
  const { isMobile } = useDimensions()

  const visibleItems = isMobile ? 1 : visibleItemsCount

  const [activeIndex, setActiveIndex] = useState(0)
  const [targetIndex, setTargetIndex] = useState(null)
  const [touchStartX, setTouchStartX] = useState(0)
  const [touchEndX, setTouchEndX] = useState(0)

  const carouselSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 350,
    slidesToShow: visibleItems,
    lazyLoad: 'ondemand',
    slidesToScroll: 1,
    afterChange: (current) => {
      setActiveIndex(current)
      setTargetIndex(null)
    },
    beforeChange: (current, next) => {
      setTargetIndex(next)
    },
  }

  // handle item change.
  const handleGoToSlide = (i) => {
    carouselRef.current.slickGoTo(i)
  }

  // swipeable / touch events.
  const handleTouchStart = (e) => {
    setTouchStartX(e.changedTouches[0].screenX)
  }

  const handleTouchEnd = (e) => {
    setTouchEndX(e.changedTouches[0].screenX)

    if (!touchStartX || !touchEndX) return

    const MIN_SWIPE_DISTANCE = 24
    const swipeDistance = touchStartX - touchEndX

    if (swipeDistance > MIN_SWIPE_DISTANCE) carouselRef.current.slickNext()
    if (swipeDistance < -MIN_SWIPE_DISTANCE) carouselRef.current.slickPrev()
  }

  // auto-play.
  const resetTimeout = () => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current)
  }

  useEffect(() => {
    resetTimeout()
    timeoutRef.current = setTimeout(() => carouselRef.current.slickNext(), 5000)
    return () => resetTimeout()
  }, [activeIndex])

  // if empty.
  if (!items.length) return null

  // Return carousel.
  return (
    <div className="x__carousel">
      <div className="x__carousel__aside">
        <Caption
          items={items}
          activeIndex={activeIndex}
          isMobile={isMobile}
          onClick={(i) => handleGoToSlide(i)}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
        />

        {!isMobile && (
          <div className="x__controls">
            <ArrowIcon direction="left" onClick={() => carouselRef.current.slickPrev()} />
            <ArrowIcon direction="right" onClick={() => carouselRef.current.slickNext()} />
          </div>
        )}
      </div>

      <div className="x__carousel__items-wrapper" style={{ maxWidth: WRAPPER_MAX_WIDTH }}>
        <Slider {...carouselSettings} ref={carouselRef}>
          {items.map((item, i) => (
            <GatsbyImage
              key={i}
              image={item.image}
              /**
               * There is an issue in react-slick with the custom transition between the last and first slides.
               * When we switch between the first and last slides `transform: scale(1)` is applied with a delay
               * and wihtout animation. Adding custom class to the slide where we are navigating and adding
               * scaling to it resolves the issue.
               */
              className={classNames({
                'slick-target': targetIndex === i,
              })}
              alt={item.title}
              loading="lazy"
            />
          ))}
        </Slider>

        {chromes}
      </div>
    </div>
  )
}

export default FadeCarousel
